import React from "react";

const Sharenew = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8455 20.5396C13.8276 24.3623 10.7276 27.47 6.9319 27.4668C3.07441 27.4635 -0.0288117 24.289 0.000448472 20.3783C0.0280831 16.6435 3.17032 13.539 6.93027 13.5342C10.7569 13.5276 13.8633 16.6761 13.8455 20.5396ZM30.3653 41C34.1659 40.9869 37.2464 37.8792 37.2545 34.0499C37.261 30.1881 34.1366 27.0478 30.3149 27.0755C26.4997 27.1032 23.4143 30.2076 23.4095 34.019C23.4062 37.858 26.5501 41.013 30.3653 41ZM30.3149 13.9224C34.135 13.9518 37.2626 10.8082 37.2545 6.94636L37.2545 6.948C37.2464 3.11872 34.1691 0.0158867 30.3653 -0.000401871C26.5517 -0.0166866 23.4046 3.14152 23.4095 6.98057C23.4127 10.7919 26.4981 13.8931 30.3149 13.9224ZM11.476 33.8652C14.129 36.1032 17.2094 37.3703 20.511 37.7596C21.6862 37.7873 22.4568 37.1342 22.5429 36.1585C22.6226 35.2594 21.9707 34.5037 20.9645 34.3278C20.8189 34.3022 20.673 34.2787 20.5271 34.2552C20.2462 34.2099 19.9655 34.1647 19.6884 34.1046C16.6323 33.4385 14.0753 31.9318 12.0271 29.5571C11.1363 28.5228 10.1057 28.3387 9.29125 29.0326C8.47521 29.7265 8.49634 30.8112 9.40666 31.8097C10.0601 32.528 10.7348 33.2397 11.476 33.8652ZM20.5351 3.23567C21.7022 3.22753 22.4435 3.86927 22.5426 4.81722C22.632 5.68536 22.0013 6.52745 21.0699 6.65286C17.5635 7.12195 14.6001 8.61066 12.1942 11.2265C11.4855 11.9969 10.4321 11.9839 9.73149 11.3063C9.03899 10.6369 8.99836 9.61562 9.70873 8.83218C12.3584 5.91177 15.6356 4.10707 19.5109 3.39855C19.7985 3.34563 20.0868 3.30291 20.3106 3.26975L20.3107 3.26974L20.3125 3.26948C20.3979 3.25682 20.4739 3.24556 20.5367 3.23567L20.5351 3.23567ZM38.963 26.0348C39.7905 23.9027 39.8945 22.914 40.0879 20.5132L40.0863 20.5116C39.6848 16.9022 39.6637 16.7556 38.963 14.9591C38.6867 13.8189 37.7292 13.3319 36.7474 13.6414C35.7558 13.9541 35.3022 14.8744 35.668 15.9982C36.6499 19.005 36.6482 21.9954 35.668 25.0021C35.3022 26.1276 35.7558 27.0446 36.7523 27.3574C37.7357 27.6668 38.6834 27.1798 38.963 26.0348Z" fill="url(#paint0_linear_1847_23340)"/>
  <defs>
    <linearGradient id="paint0_linear_1847_23340" x1="66.6253" y1="-14.4432" x2="-11.0244" y2="-6.55026" gradientUnits="userSpaceOnUse">
      <stop offset="0.576664" stop-color="#E8D6FF"/>
      <stop offset="0.993966" stop-color="#ECDCFF"/>
    </linearGradient>
  </defs>
</svg>
  );
};

export default Sharenew;
